import Swiper from 'swiper';
import {Pagination} from 'swiper/modules';

export default {

    init() {
        // JavaScript to be fired on the rubric page listing
        // get rubric active
        this.rubricActive = ($('.taxonomy-children-item.active').length > 0) ? $('.taxonomy-children-item.active').data('rubric') : null;

        this.breakpoint = window.matchMedia( '(min-width:768px)' );
        this.breakpoint.addEventListener("change", (e) => {
           //  console.log('event', e)
            this.breakpointChecker();
        });

        this.breakpointChecker();

        if ( !$('body').hasClass('post-type-archive-solution') ) {
            this.initRubricFilter();
        }

        // fix maillage solution
        if ( !$('body').hasClass('tax-rubric') ) {
            this.finalize();
        }

    },

    breakpointChecker ( event ) {
        // if larger viewport and multi-row layout needed
        // console.log (this);
        if ( this.breakpoint.matches === true ) {
            // console.log (this.TaxSwiper);
            // clean up old instances and inline styles when available
            if ( this.TaxSwiper !== undefined ) this.TaxSwiper.destroy( true, true );
            if ( this.SolutionSwiper !== undefined ) this.SolutionSwiper.destroy( true, true );

            if ( this.rubricActive ) {
                this.applyFilters( this.rubricActive );
            } else {
                if ( $('.taxonomy-children-item').length == 0 ) {
                    // console.log ($('.taxonomy-children-item').length);
                    this.applyFilters();
                }
            }

            return;

            // else if a small viewport and single column layout needed
        } else if ( this.breakpoint.matches === false ) {

            // fire small viewport version of swiper
            return this.enableSwiper();

        }
    },

    enableSwiper () {
        let sliderIndex = -1;
        sliderIndex =  $('.swiper-slide', '.taxonomy-children-list').index( $('.swiper-slide.active'));
        // console.log('enable Swiper');
        if ( $('.taxonomy-children-item').length > 0 ) {
            if ( this.TaxSwiper === undefined || this.TaxSwiper.destroyed ) {
                this.TaxSwiper = new Swiper ('.taxonomy-children-list', {
                    slidesPerView: 1.2,
                    spaceBetween: 16,
                    // pagination
                    pagination: {
                        el: ".swiper-pagination",
                        type: "fraction",
                    },

                    on: {
                        init: (swiper) => {
                            if (sliderIndex != -1) {
                                swiper.slideTo(sliderIndex);
                            }
                        }
                    },

                    modules: [Pagination]
                });
            }
        }

        if ( this.SolutionSwiper === undefined || this.SolutionSwiper.destroyed ) {
            this.SolutionSwiper = new Swiper ('.rubric-solutions-list', {
                slidesPerView: 1.2,
                spaceBetween: 16,
                // pagination
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                },

                modules: [Pagination]
            });
        }
    },

    initRubricFilter () {
        $('.taxonomy-children-item').on ('click', (e) => {
            if (this.breakpoint.matches === true) {
                $('html,body').animate({
                    scrollTop: $(".rubric-solutions").offset().top - $('.banner').height()
                }, 500);
            }

            let $elem = $(e.currentTarget);
            let rubric = $elem.data('rubric');
            this.rubricActive = rubric;
            // console.log('filter----');

            if (!$elem.hasClass('active')) {
                $('.taxonomy-children-item.active').removeClass('active');
                $elem.addClass('active');

                history.pushState({ 'classID': rubric }, '', $elem.attr('href'));

                this.applyFilters(rubric);
            }

            return false;
        })

        // gérer les précédent/suivant dans le navigateur
        window.onpopstate =  (e) => {
            // active rubric filter
            $('.taxonomy-children-item.active').removeClass('active');
            $('.taxonomy-children-item[data-rubric="'+ e.state.classID + '"]').addClass('active');

            // filter solutions
            this.applyFilters(e.state.classID);
        };
    },

    applyFilters (rubric) {
        let $carSolution = $('.card-solution');
        let $solutionNumber = $('.rubric-solutions h2');
        let $solutionNumberWrapper = $('.rubric-solutions .title-wrapper');
        let numbers = 0;

        $carSolution.each( (index, el) => {
            let visible = true;
            // console.log (rubric);
            if (rubric) {
                visible = $(el).hasClass(rubric);
            }

            if (visible) {
                $(el).fadeIn().css('display', 'flex');
                numbers++ ;
            } else {
                $(el).hide();
            }
        });

        if ( !$('body').hasClass('single') ) {
            if ( numbers == 0) {
                $solutionNumberWrapper.hide();
            } else {
                $solutionNumberWrapper.show().css('display', 'flex');

                if ( numbers == 1 ) {
                    $solutionNumber.text('1 solution');
                } else {
                    $solutionNumber.text(numbers + ' solutions');
                }
            }
        }
    },

    finalize () {
        $(window).on ('load', () => {
            // console.log ($('.taxonomy-children-item').length, this.rubricActive);
            if ( this.rubricActive ) {
                this.applyFilters( this.rubricActive );
            } else {
                if ( $('.taxonomy-children-item').length == 0 ) {
                    // console.log ($('.taxonomy-children-item').length);
                    this.applyFilters();
                }
            }
        });
    }
};
